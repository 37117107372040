import {
  FilterHeading,
  SearchkitFilterHeader
} from '@/components/atomic/atoms';
import {
  RECORDS_OPTIONS,
  TAB_CATEGORY_ATTRIBUTE
} from '@/config/searchkit/navigation';

const DynamicFilter = ({
  Component,
  componentProps,
  headerProps,
  showFilter = true
}) =>
  showFilter && (
    <div className='mb-4'>
      <FilterHeading {...headerProps} />
      <Component {...componentProps} />
    </div>
  );

const NavigationFilters = ({
  clearBookingDateFilter,
  clearCreatedAtDateFilter,
  clearEventDateFilter,
  dynamicFilterProps,
  setClearBookingDateFilter,
  setClearCreatedAtDateFilter,
  setClearEventDateFilter,
  setSelectedOption,
  setShowFilters,
  showFilters
}) => (
  <div
    className={`flex flex-col fixed bg-white top-40 h-[80vh] filter-scroll overflow-x-hidden overflow-y-auto w-[20rem] searchkit-filter-container`}
  >
    <SearchkitFilterHeader
      {...{
        excludedAttributes: [TAB_CATEGORY_ATTRIBUTE, 'query'],
        onClickClearAll: () => {
          setClearBookingDateFilter(!clearBookingDateFilter);
          setClearCreatedAtDateFilter(!clearCreatedAtDateFilter);
          setClearEventDateFilter(!clearEventDateFilter);
          setSelectedOption(RECORDS_OPTIONS.ALL_RECORDS);
        },
        setShowFilters,
        showFilters
      }}
    />
    <div className={`${showFilters ? 'visible z-10' : 'hidden'}`}>
      <div className='w-[18rem] hidden md:block mb-10'>
        <div className='searchkit-filter-container pl-5'>
          {dynamicFilterProps.map(
            ({ Component, componentProps, headerProps }, index) => (
              <div key={`${componentProps.attribute}_${index}`}>
                <DynamicFilter
                  {...{
                    Component,
                    componentProps,
                    headerProps
                  }}
                />
                <div className='border-t border-platinum mt-3 mb-4'></div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  </div>
);

export default NavigationFilters;
