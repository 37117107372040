import _ from 'lodash';
import { useRouter } from 'next/router';
import { memo, useMemo, useState } from 'react';
import {
  useClearRefinements,
  useCurrentRefinements,
  useInstantSearch,
  useSearchBox,
  useStats
} from 'react-instantsearch';

import {
  CustomInfiniteHits,
  ListCard,
  NoResultsBoundary,
  PageNotFound,
  TabMenu
} from '@/components/atomic/atoms';
import {
  NavigationFilters,
  NavigationSearchBar
} from '@/components/atomic/molecules';
import {
  CloseIcon,
  CurrentRefinements,
  Text
} from '@/components/atomic/nuclei';
import { isProduction } from '@/config/common';
import {
  ALL_TAB_CATEGORY_CONFIG,
  DATE_FILTER_ATTRIBUTE,
  DEFAULT_TAB_CATEGORY,
  RECORDS_OPTIONS,
  TAB_CATEGORY_ATTRIBUTE,
  TAB_CATEGORY_CONFIG_LIST
} from '@/config/searchkit/navigation';
import transformCurrentRefinementItemsLabel, {
  DATE_RANGE_ITEMS_FOR_EVENT_DATE,
  DEFAULT_NUMERIC_REFINEMENT,
  transformCartStatusRadio,
  transformHostInteractionStatusRadio,
  transformHostTypeRadio,
  transformOrderStatusRadio,
  transformUserEventStageRadio
} from '@/helpers/searchkit';
import {
  getFilterConfig,
  transformSearchResultList
} from '@/services/navigation.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const SearchResultsPanel = ({ memoizedHandleTransformItems }) => {
  const { query } = useSearchBox();

  return (
    <div className='flex justify-center gap-6 px-6'>
      <NoResultsBoundary
        fallback={
          <PageNotFound
            {...{
              className: 'py-20',
              description: `Sorry, we couldn't find any matches for "${query}"`,
              iconHeight: 314,
              iconWidth: 805,
              notFoundImage: 'search-result-not-found.svg',
              title: 'No search result'
            }}
          />
        }
      >
        <CustomInfiniteHits
          {...{
            infiniteHitsProps: {
              classNames: {
                item: 'p-0 border-none shadow-none',
                list: 'flex flex-col gap-4',
                loadMore: 'border-none hover:bg-none text-brand font-medium',
                root: 'flex flex-col flex-1'
              },
              hitComponent: ({ hit: listCard }) => (
                <ListCard {...{ listCard }} />
              ),
              showPrevious: false,
              transformItems: memoizedHandleTransformItems,
              translations: {
                showMoreButtonText: 'Show more'
              }
            }
          }}
        />
      </NoResultsBoundary>
    </div>
  );
};

const NavigationBodyOrganism = memo(
  ({
    query,
    register,
    searchableAttribute,
    selectedOption,
    selectedTabMenu,
    setQuery,
    setSearchableAttribute,
    setSelectedOption,
    setSelectedTabMenu,
    setShowResults,
    showResults
  }) => {
    const [showFilters, setShowFilters] = useState(true);
    const [clearBookingDateFilter, setClearBookingDateFilter] =
      useState(false);
    const [clearEventDateFilter, setClearEventDateFilter] = useState(false);
    const [clearCreatedAtDateFilter, setClearCreatedAtDateFilter] =
      useState(false);

    const router = useRouter();

    const { items: currentRefinements } = useCurrentRefinements();
    const { refine: clearRefinements } = useClearRefinements({
      excludedAttributes: [TAB_CATEGORY_ATTRIBUTE]
    });
    const { nbHits } = useStats();
    const resultsCount = nbHits >= 10000 ? '10000+' : nbHits;

    const { setUiState } = useInstantSearch();

    const handleTransformItems = (newHits) => {
      const transformedHits = transformSearchResultList({
        searchResults: newHits
      });

      return transformedHits;
    };

    const hasResult = !_.isUndefined(resultsCount);

    const removeCurrentRefinement = (attribute) => {
      const toggleActions = {
        [DATE_FILTER_ATTRIBUTE.BOOKING_DATE]: () =>
          setClearBookingDateFilter(!clearBookingDateFilter),
        [DATE_FILTER_ATTRIBUTE.EVENT_DATE]: () =>
          setClearEventDateFilter(!clearEventDateFilter),
        [DATE_FILTER_ATTRIBUTE.CREATED_AT_DATE]: () =>
          setClearCreatedAtDateFilter(!clearCreatedAtDateFilter)
      };
      const executeToggle = toggleActions[attribute];
      if (executeToggle) {
        executeToggle();
      }
    };

    const memoizedHandleTransformItems = useMemo(
      () => handleTransformItems,
      []
    );

    const memoizedRemoveCurrentRefinement = useMemo(
      () => removeCurrentRefinement,
      [clearEventDateFilter, clearBookingDateFilter, clearCreatedAtDateFilter]
    );

    const memoizedOrderStatusTransform = useMemo(
      () => transformOrderStatusRadio({ attribute: 'status' }),
      []
    );

    const memoizedCartStatusTransform = useMemo(
      () => transformCartStatusRadio({ attribute: 'status' }),
      []
    );

    const memoizedHostInteractionStatusTransform = useMemo(
      () =>
        transformHostInteractionStatusRadio({
          attribute: 'interactionStatus'
        }),
      []
    );

    const memoizedHostTypeTransform = useMemo(
      () => transformHostTypeRadio({ attribute: 'hostSegment' }),
      []
    );

    const memoizedUserEventStageTransform = useMemo(
      () => transformUserEventStageRadio({ attribute: 'stage' }),
      []
    );

    const defaultRefinementForEventDate =
      selectedOption === RECORDS_OPTIONS.MY_RECORDS
        ? encodeURI(
            `{"start":${DATE_RANGE_ITEMS_FOR_EVENT_DATE.UPCOMING.start}}`
          )
        : DEFAULT_NUMERIC_REFINEMENT;

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };

    const entityLabel = `${
      TAB_CATEGORY_CONFIG_LIST.find(({ key }) => key === selectedTabMenu)
        ?.label
    }s`;

    const radioInputProps = [
      {
        checked: selectedOption === RECORDS_OPTIONS.MY_RECORDS,
        label: `My ${entityLabel}`,
        value: RECORDS_OPTIONS.MY_RECORDS
      },
      {
        checked: selectedOption === RECORDS_OPTIONS.ALL_RECORDS,
        label: `All ${entityLabel}`,
        value: RECORDS_OPTIONS.ALL_RECORDS
      }
    ];

    const FILTER_CONFIG = getFilterConfig({
      clearBookingDateFilter,
      clearCreatedAtDateFilter,
      clearEventDateFilter,
      defaultRefinementForEventDate,
      entityLabel,
      handleOptionChange,
      memoizedCartStatusTransform,
      memoizedHostInteractionStatusTransform,
      memoizedHostTypeTransform,
      memoizedOrderStatusTransform,
      memoizedUserEventStageTransform,
      radioInputProps,
      selectedOption,
      setClearBookingDateFilter,
      setClearCreatedAtDateFilter,
      setClearEventDateFilter,
      setSelectedOption,
      setUiState
    });

    const dynamicFilterProps = FILTER_CONFIG[selectedTabMenu] || [];

    const isFilterAvailable = !isProduction && dynamicFilterProps.length > 0;

    return (
      <div className='relative'>
        <div className='sticky top-0 bg-white pt-4 flex flex-col justify-center items-center h-36 text-lg font-medium z-101'>
          <div className='absolute top-8 right-5 z-10'>
            <CloseIcon
              {...{
                height: 32,
                icon: 'close-brand-icon.svg',
                onClick: () => {
                  // TODO: Faizan/Hardik - use next/router. Do not use window.*
                  if (window.navigation.canGoBack) {
                    router.back();
                  } else {
                    router.replace(
                      getPageURL({ pageName: PAGE_NAME.WELCOME.label })
                    );
                  }
                },
                width: 32
              }}
            />
          </div>
          <TabMenu
            {...{
              allTabMenuConfig: ALL_TAB_CATEGORY_CONFIG,
              attribute: TAB_CATEGORY_ATTRIBUTE,
              defaultTabCategory: DEFAULT_TAB_CATEGORY,
              onTabMenuSwitch: () => {
                clearRefinements();
                setSelectedOption(RECORDS_OPTIONS.MY_RECORDS);
              },
              selectedTabMenu,
              setQuery,
              setSelectedTabMenu,
              setShowResults,
              showTabMenuCount: !isProduction,
              tabCategoryConfigList: TAB_CATEGORY_CONFIG_LIST
            }}
          />
          <NavigationSearchBar
            {...{
              query,
              register,
              searchableAttribute,
              selectedTabMenu,
              setQuery,
              setSearchableAttribute,
              setShowResults
            }}
          />
        </div>
        <div className='flex gap-6'>
          {isFilterAvailable && (
            <NavigationFilters
              {...{
                clearBookingDateFilter,
                clearCreatedAtDateFilter,
                clearEventDateFilter,
                dynamicFilterProps,
                setClearBookingDateFilter,
                setClearCreatedAtDateFilter,
                setClearEventDateFilter,
                setSelectedOption,
                setShowFilters,
                showFilters
              }}
            />
          )}
          <div
            className={`flex flex-col px-2 w-full h-screen relative ${isFilterAvailable && showFilters ? 'ml-80' : 'mt-[3rem]'}`}
          >
            {showResults ? (
              <>
                {isFilterAvailable && (
                  <div
                    className={`${currentRefinements.length > 0 ? 'mb-4 mt-4' : 'my-0'}`}
                  >
                    {hasResult && (
                      <Text
                        {...{
                          className: 'text-xl font-medium text-brand mb-4',
                          content: `${resultsCount} ${entityLabel}`
                        }}
                      />
                    )}
                    <CurrentRefinements
                      {...{
                        excludedAttributes: ['query', TAB_CATEGORY_ATTRIBUTE],
                        removeCurrentRefinement:
                          memoizedRemoveCurrentRefinement,
                        transformItems: transformCurrentRefinementItemsLabel
                      }}
                    />
                  </div>
                )}
                <SearchResultsPanel {...{ memoizedHandleTransformItems }} />
              </>
            ) : (
              <PageNotFound
                {...{
                  alt: 'entity not found',
                  className: 'py-4',
                  iconHeight: 513,
                  iconWidth: 805,
                  notFoundImage: 'entity-not-found-icon.svg',
                  title: ''
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

NavigationBodyOrganism.displayName = 'NavigationBodyOrganism';

export default NavigationBodyOrganism;
