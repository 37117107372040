import { useMemo, useState } from 'react';
import { Menu, RefinementList } from 'react-instantsearch';

import {
  FilterHeading,
  NumericFilterWithDatePicker
} from '@/components/atomic/atoms';
import { ORDER_STATUS } from '@/config/common';
import {
  DATE_FILTER_ATTRIBUTE,
  DEFAULT_FILTER_OPTIONS_LIMIT
} from '@/config/searchkit/orders';
import {
  DATE_RANGE_ITEMS_FOR_EVENT_DATE,
  DEFAULT_NUMERIC_REFINEMENT,
  transformOrderStatusRadio
} from '@/helpers/searchkit';
import useEventListener from '@/hooks/useEventListener';

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-4 max-h-80 overflow-auto',
  searchBox: 'searchkit-search-box',
  searchableForm: 'searchkit-search-box-form',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

const DynamicFilter = ({
  Component,
  componentProps,
  headerProps,
  showFilter = true
}) =>
  showFilter && (
    <div className='mb-4'>
      <FilterHeading {...headerProps} />
      <Component {...componentProps} />
    </div>
  );

const OrderListFilters = ({
  clearBookingDateFilter,
  clearEventDateFilter,
  setClearBookingDateFilter,
  setClearEventDateFilter,
  showFilters,
  showMyUpcomingOrders
}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 0);
  };

  useEventListener('scroll', handleScroll);

  const memoizedOrderStatusTransform = useMemo(
    () => transformOrderStatusRadio({ attribute: 'status' }),
    []
  );
  const defaultRefinementForEventDate = showMyUpcomingOrders
    ? encodeURI(`{"start":${DATE_RANGE_ITEMS_FOR_EVENT_DATE.UPCOMING.start}}`)
    : DEFAULT_NUMERIC_REFINEMENT;

  const dynamicFilterProps = [
    {
      componentProps: {
        attribute: DATE_FILTER_ATTRIBUTE.BOOKING_DATE,
        clearDateFilter: clearBookingDateFilter,
        customRangeKey: 'customRangeForBookingDate',
        defaultRefinement: DEFAULT_NUMERIC_REFINEMENT,
        items: [{}],
        showAdditionalFilters: false
      },
      Component: NumericFilterWithDatePicker,
      headerProps: {
        heading: 'Booking Date',
        includedAttributes: DATE_FILTER_ATTRIBUTE.BOOKING_DATE,
        onClick: () => {
          setClearBookingDateFilter(!clearBookingDateFilter);
        }
      }
    },
    {
      componentProps: {
        attribute: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
        clearDateFilter: clearEventDateFilter,
        customRangeKey: 'customRangeForEventDate',
        defaultRefinement: defaultRefinementForEventDate,
        items: Object.values(DATE_RANGE_ITEMS_FOR_EVENT_DATE)
      },
      Component: NumericFilterWithDatePicker,
      headerProps: {
        heading: 'Event Date',
        includedAttributes: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
        onClick: () => {
          setClearEventDateFilter(!clearEventDateFilter);
        }
      }
    },

    {
      componentProps: {
        attribute: 'plannerName',
        classNames: {
          ...classNameGroup,
          list: `${classNameGroup.list} mt-4`,
          searchableForm: 'searchkit-search-box-form',
          searchBox: 'searchkit-search-box searchkit-icon',
          selectedItem: 'searchkit-selected-item'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      Component: RefinementList,
      headerProps: {
        heading: 'Planner Name',
        includedAttributes: 'plannerName'
      }
    },
    {
      Component: Menu,
      componentProps: {
        attribute: 'status',
        classNames: {
          count: classNameGroup.count,
          label:
            'searchkit-filter-menu-label text-sm text-dim-gray font-medium cursor-pointer',
          item: 'h-5',
          list: 'flex flex-col gap-4 mt-4',
          selectedItem: 'searchkit-filter-menu-selected-item',
          showMore: classNameGroup.showMore
        },
        limit: Object.keys(ORDER_STATUS).length,
        transformItems: memoizedOrderStatusTransform
      },
      headerProps: {
        heading: 'Order Status',
        includedAttributes: 'status'
      }
    }
  ];

  return (
    <div
      className={`${showFilters ? 'visible' : 'hidden'} ${
        isScrolled ? '!top-20' : ''
      } w-[20rem] flex flex-col fixed bg-white z-10 top-40 h-[80vh] filter-scroll overflow-x-hidden overflow-y-auto`}
    >
      <div className='w-[18rem] hidden md:block mb-10'>
        <div className='searchkit-filter-container pl-5'>
          {dynamicFilterProps.map(
            ({ Component, componentProps, headerProps }, index) => (
              <div key={`${componentProps.attribute}_${index}`}>
                <DynamicFilter
                  {...{
                    Component,
                    componentProps,
                    headerProps
                  }}
                />
                <div className='border-t border-platinum mt-3 mb-4'></div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderListFilters;
